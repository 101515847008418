body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.App {
  text-align: center; }

.App-header {
  margin: 0 2rem;
  min-height: 75vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  h1 {
    margin: 0 0 1rem 0; }
  p, h3, h5 {
    margin: 1rem 0 0 0; }
  .definition {
    margin-bottom: 3rem; } }

.abide {
  color: #755c00; }
